// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-author-js": () => import("./../src/pages/author.js" /* webpackChunkName: "component---src-pages-author-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-post-list-js": () => import("./../src/templates/postList.js" /* webpackChunkName: "component---src-templates-post-list-js" */),
  "component---src-templates-posts-author-js": () => import("./../src/templates/postsAuthor.js" /* webpackChunkName: "component---src-templates-posts-author-js" */),
  "component---src-templates-singe-post-js": () => import("./../src/templates/singePost.js" /* webpackChunkName: "component---src-templates-singe-post-js" */),
  "component---src-templates-tag-posts-js": () => import("./../src/templates/tagPosts.js" /* webpackChunkName: "component---src-templates-tag-posts-js" */),
  "component---src-templates-tags-page-js": () => import("./../src/templates/tagsPage.js" /* webpackChunkName: "component---src-templates-tags-page-js" */)
}

